.sitemap-section {
    color: color(text-darker);
    z-index: 10;

    // margin-top: -500px;
    .section-border {
        background-size: cover !important;
        height: 445px;
        z-index: 10;
    }

    .page-content {
        z-index: 10;
        background: color(white-clr);
        // padding-top: 380px;
        padding-bottom: 380px;
    }

    .main-content {
        z-index: 30;
        position: relative;
        top: -80px;
    }

    .sitemap-title {
        font-weight: 200;
        font-family: font(poppins);
        text-align: center;
        font-size: 2.857rem;
    }

    hr.sitemap-separator {
        border-top: 3px solid color(text-darker);
        width: 30px;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .sitemap-subtitle {
        font-weight: 300;
        font-family: font(roboto);
        text-align: center;
        font-size: 1.143rem !important;
    }

    .sitemap-text {
        text-align: center;
        color: color(text-darker);

        p {
            font-size: 1rem;
            font-size: 1rem;
            font-family: font(roboto);
            font-weight: 300;
            padding-bottom: 1rem;
        }

        ul {
            font-size: 1rem;

            li {
                a {
                    &:hover {
                        color: color(primary-clr);
                    }
                }
            }
        }
    }
}