.grid-wrapper {
    padding: 100px 0;
    .grid-title {
        font-size: 3.2rem;
        margin-bottom: 10px;
    }
}
.grid-container {
    margin-bottom: 0;
    .grid-item {
        margin: 15px 0;
        text-align: center;
        font-size: 1.5rem;
        border-radius: $border_radius;
        img {
            max-width: 100%;
            box-shadow: 1px 1px 10px rgba(0,0,0,0.1);
            margin-bottom: 15px;
            transition: box-shadow 0.2s;
        }
        &:hover {
            a {
                text-decoration: none;
            }
            img {
                box-shadow: 1px 1px 1px rgba(0,0,0,0.1);
            }
        }
    }
}
/* MEDIA QUERIES */
@media(max-width: $media_sm) {
    .grid-wrapper {
        padding: 60px 0;
    }
}