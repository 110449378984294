.reference-detail-section {
    color: color(text-darker);
    z-index: 10;
    margin-top: -500px;

    .section-border {
        background-size: cover !important;
        height: 445px;
        z-index: 10;
    }

    .page-content {
        z-index: 10;
        background: color(white-clr);
        // padding-top: 380px;
        padding-bottom: 150px;

        @media (max-width: $media_md) {
            padding-bottom: 120px;
        }

        @media (max-width: $media_sm) {
            padding-bottom: 100px;
        }
    }

    .main-content {
        z-index: 30;
        position: relative;
        top: -100px;

        @media(max-width: $media_sm) {
            top: -180px;
        }

        @media (max-width: $media_xs) {
            top: -220px;
        }
    }

    .reference-title {
        font-weight: 200;
        font-family: font(poppins);
        text-align: center;
        font-size: 2.857rem;
    }

    hr.reference-separator {
        border-top: 3px solid color(text-darker);
        width: 30px;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .reference-subtitle {
        font-weight: 300;
        font-family: font(roboto);
        text-align: center;
        font-size: 1.143rem !important;
    }

    .reference-text {
        font-family: font(roboto);
        font-weight: 300;

        @include page-text();
    }

    .gallery-wrapper {
        margin-top: 6rem;
        margin-bottom: 6rem;
    }

    .customer-reference-box {
        margin-top: 4rem;
    }

    .customer-link {
        text-align: center;
        font-family: font(poppins);
        font-weight: 500;
        font-size: 1.143rem !important;
        border: 1px solid #cccccc;
        color: color(text-darker);
        padding: 15px;
        @include transition(all .3s linear);

        i {
            color: color(primary-clr);
            font-size: 1.2rem;
            padding-left: 10px;
        }

        &:hover {
            text-decoration: none;
            background-color: color(primary-clr);
            color: color(white-clr);
            border: 1px solid color(primary-clr);

            i {
                color: color(white-clr);
            }
        }
    }
}