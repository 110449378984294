.testimonials-section {
    .page-content {
        z-index: 10;

        // padding-top: 380px;
        padding-bottom: 200px;

        @media (max-width: $media_md) {
            padding-bottom: 200px;
        }

        @media (max-width: $media_sm) {
            padding-bottom: 200px;
        }
    }

    .main-content {
        z-index: 30;
        position: relative;
        color: color(text-darker);
        top: -50px;

        @media(max-width: $media_sm) {
            top: -90px;
        }

        @media (max-width: $media_xs) {
            top: -110px;
        }
    }

    .testimonials-title {
        font-weight: 200;
        font-family: font(poppins);
        text-align: center;
        font-size: 2.857rem;
    }

    hr.testimonials-separator {
        border-top: 3px solid color(text-darker);
        width: 30px;
    }

    .testimonials-slider {
        .slick-slide {
            height: auto;
        }

        .slide {
            text-align: center;

            .testimonial-text {
                p {
                    font-family: font(roboto);
                    font-weight: 300;
                    font-size: 1.143rem;
                    width: auto;
                    max-width: 80%;
                    display: inline-block;
                    padding: 30px 0;

                    @media(max-width: $media_md) {
                        max-width: 100%;
                        display: block;
                    }
                }
            }

            .testimonial-quote-start {
                background-repeat: no-repeat;
                display: inline-block;
                vertical-align: top;
                height: 41px;
                width: 66px;

                @media(max-width: $media_md) {
                    display: block;
                    margin: 0 auto;
                    vertical-align: middle;
                }
            }

            .testimonial-quote-end {
                background-repeat: no-repeat;
                display: inline-block;
                vertical-align: bottom;
                height: 45px;
                width: 65px;

                @media(max-width: $media_md) {
                    display: block;
                    margin: 0 auto;
                    vertical-align: middle;
                }
            }

            .testimonial-author {
                font-family: font(poppins);
                font-size: 1.143rem;
                font-weight: 500;
            }
        }

        .navi {
            position: absolute;
            bottom: 0;
            // margin-top: -25px;
            z-index: 1;
            cursor: pointer;
            font-size: 1.2rem;
            background: transparent;
            border: none;
            color: color(text-darker) !important;


            &.navi-prev {
                left: 40%;
            }

            &.navi-next {
                right: 40%;
            }

            @media (max-width: 1250px) {
                &.navi-prev {
                    left: -20px;
                }

                &.navi-next {
                    right: -20px;
                }
            }
        }
    }
}