// .navbar-light {

//     .nav-link {
//         font-size: 1.5rem;
//         color: rgba(0, 0, 0, 1) !important;

//         &.btn-gradient {
//             color: #fff !important;

//             &:hover {
//                 color: #fff !important;
//             }
//         }

//         &:hover {
//             color: $clr-theme-light !important;
//         }
//     }
// }

// @media (max-width: $media_xs) {
//     .navbar-light {
//         .navbar-brand {
//             max-width: 70%;
//             display: inline-block;

//             .navbar-brand-image {
//                 max-width: 100%;
//             }
//         }
//     }
// }

// @media (max-width: $media_lg) {
//     .mobile-nav {
//         flex-basis: 100%;
//         flex-grow: 1;
//         align-items: center;

//         .list-inline-item {
//             a {
//                 display: block;
//                 padding: 0.5rem 2rem;
//                 text-align: center;

//                 &:hover {
//                     text-decoration: none;
//                     color: $clr-theme-light;
//                 }
//             }
//         }
//     }
// }


/* HEADER STYLES */
$margin_y: 20px;

.header-container {
    width: 100%;
    max-width: 100vw;
    padding: 0 30px;
    color: color(white-clr);
    height: auto;
    overflow-x: hidden;
    &.fixed-top{
        backface-visibility: hidden;
    }
    &.scrolled {
        background-color: #0a141e !important;
        @include transition(background-color .3s linear);
    }

    .company-brand {
        text-align: right;
    }

    /* Header logo */
    .header-brand{
        text-align: center;
        @media(max-width: $media_lg){
            text-align: left;
            img {
                margin-left: 25px;
            }
        }
        
        @media(max-width: $media_md){
            img {
                margin-left: 0px;
            }
            
        }
        @media(max-width: 767px){
            img{margin-left: 125px;}
        }
        @media (max-width: $media_sm){
            img {
                margin-left: 30px;
            }
        }
        @media(max-width: 700px){
            img{
                margin-left: 115px;
            }
        }
        @media(max-width: 680px){
            img{
                margin-left: 105px;
            }
        }
        @media(max-width: 650px){
            img{margin-left: 95px;}
        }
        @media(max-width: 620px){
            img{margin-left: 85px}
        }
        @media(max-width: 600px){
            img{margin-left: 75px}
        }
        @media(max-width: 560px){
            img{margin-left: 70px;}
        }
        @media(max-width: 530px){
            img{margin-left: 60px;}
        }
        @media(max-width: 500px){
            img{margin-left: 50px;}
        }
        @media(max-width: 480px){
            img{margin-left: 40px;}
        }
        @media(max-width:450px){
            img{margin-left: 30px;}
        }
        @media(max-width: $media_xs){
            
            img {
                margin-left: 25px;
                
            }
        }
        @media(max-width: 400px){
            img{margin-left: 15px}
        }
        @media(max-width: 370px){
            img{margin-left: 10px;}
        }
        @media(max-width: 355px){
            img{margin-left: 5px;}
        }
        @media(max-width: 330px){
            img{margin-left: 0px;}
        }
    }
    img {
        margin: $margin_y 0;
        
    }

    .social-container {
        position: relative;
        margin: $margin_y 0;
        padding: 5.5px 0;

        .social-popup,
        .social-popup-trigger {
            display: none;
        }

        .social-link {
            color: #ffffff;
            text-align: center;
            padding-left: 0;
            border: none;
            line-height: 21px;


            &:not(:first-of-type) {
                padding-left: 10px;
            }

            &:not(:last-of-type) {
                border-right: 1px solid $border_light !important;
                padding-right: 10px;
            }

            &:last-of-type {
                padding-right: 25px;
            }

            &:first-of-type {
                padding-left: 25px;
                padding-right: 15px;
            }


            i {
                vertical-align: middle;
                font-size: 1rem;


            }
        }
    }
}

/* HEADER CONTACT */
.contact-info {
    margin: $margin_y 0;
    text-align: right;
    padding: 5.5px 25px 5.5px 0;
    border-right: 1px solid $border_light;
    display: inline-block;

    span {
        display: inline-block;
        border-right: 1px solid $border_light;
        color: color(white-clr);
        padding: 0 10px;
        font-size: 1rem;

        a {
            color: color(white-clr);

            &:hover {
                text-decoration: none;
            }
        }

        i {
            color: color(primary-clr);


            &.fa-phone {
                font-size: 1rem;
            }

            &.fa-skype {
                font-size: 1.4rem;
            }

            &.fa-envelope {
                font-size: 1rem;
            }
        }

        .nav-contact-text {
            display: none;

            @media (min-width: 1600px) {

                display: inline;
            }
        }

        &:last-of-type {
            border: 0;
            padding-right: 0;
        }

        &.text-muted {
            display: inline;
            margin-left: 1px;
        }

    }

    label {
        display: inline;
        margin: 0;
    }
}

.lang-wrap {
    margin: $margin_y 0;
    border-left: 1px solid $border-light;
    padding: 5.5px 0 5.5px 10px;

    @media (max-width: 992px) {
        justify-content: center;
        display: flex;
        align-items: center;
        border: none;
    }

    a {
        text-decoration: none;
        text-transform: uppercase;
        font-size: 1rem;
        font-family: font(roboto);
        font-weight: 600;
        color: color(white-clr);
        padding-right: 15px;

        @include transition(all .3 linear);



        &.active {
            color: color(primary-clr);
        }

        &:hover {
            opacity: 0.7;
        }

        &:not(:first-of-type) {
            padding-left: 10px;
        }

        &:not(:last-of-type) {
            border-right: 1px solid $border-light;
            padding-right: 10px;
            padding-left: 25px;

            @media (max-width: 992px) {
                padding-right: 20px;
            }
        }

        @media (max-width: 992px) {
            padding-left: 0 !important;
            border: none !important;
        }
    }
}
/* BUTTON HAMBURGER */
.btn-menu {
    margin-left: auto;
    display: inline-block;
    padding: $margin_y+10;
    color: color(white-clr);

    &:hover {
        text-decoration: none;
    }

    .menu-text {
        text-transform: uppercase;
        font-size: 1rem;
        font-family: font(roboto);
        font-weight: 600;
        line-height: 32px;
        position: relative;
        top: -5px;
        left: 10px;
    }

    span {
        display: block;
        background-color: color(white-clr);
        width: 20px;
        height: 2px;
        margin-bottom: 4px;
    }
}

/* MEDIA QUERIES */
@media (max-width: 1700px) {
    .contact-info {
        span {
            line-height: 30px;
            padding: 0 8px;

            i {
                margin-right: 5px;
            }
        }
    }
}

@media (max-width: 1600px) {
    .header-container {
        .social-container {
            .social-link {
                width: 40px;
                line-height: 30px;
            }
        }
    }

    .contact-info {
        span {
            line-height: 30px;
            padding: 0 8px;

            i {
                margin-right: 5px;
            }
        }
    }
}

@media (max-width: 1300px) {
    .contact-info {

        //text-align: left;
        span {
            label {
                display: none;
            }
        }
    }
}

@media(max-width: 1040px) {
    .contact-info {
        span {
            padding: 0 4px;
            border: 0 !important;
            font-size: 1.1rem;
        }
    }
}

@media (max-width: $media_xl) {
    .contact-info {
        .text-muted {
            display: none;
        }
    }
}

@media (max-width: $media_lg) {
    .header-container {
        .company-brand {
            text-align: center !important;
        }
    }

    .btn-menu {
        .menu-text {
            display: none !important;
        }
    }

    .mobile-lang {
        font-size: 1rem;
        height: 38px;
    }

    .mobile-book {
        position: relative;
        top: 45px;
        font-size: 1.2rem;
        background-color: color(btn-clr-large);

        i {
            color: color(white-clr);
        }
    }
}

@media (max-width: $media_sm) {
    .header-container {
        padding: $margin_y;
        padding-top: 0;
        padding-bottom: 0;
    }
}

@media (max-width: $media_xs) {

    .btn-menu {
        padding-right: 0;
        padding-bottom: 0;
    }
}