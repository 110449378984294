/* SLIDER COMPONENT STYLES */
.slider-component-container {
    padding: 80px 0;

    

    .slider-title {
        text-align: center;
        max-width: 760px;
        margin: 0 auto 15px;
    }

    .slider-text {
        text-align: center;
        margin: 0 auto;
    }

    &:nth-of-type(odd) {
        background-color: $background_alt;
    }
}

.slider-component {

    /*    padding: 20px 0 0; */
    header * {

        margin: 0;

    }

    p {
        margin: 1.5rem 0;

    }

    img {
        width: 100%;

        margin: auto;
    }

    .navi {
        &.navi-prev {
            left: -60px;
        }

        &.navi-next {
            right: -60px;
        }
    }

    .slick-dots {
        bottom: 0;
    }

    .text-content {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;

        ul {
            li {
                font-size: 1.4rem;
                font-weight: 300;

                strong,
                b {
                    color: $secondary;
                    font-weight: 700;
                }

                &:before {
                    font-family: "FontAwesome";
                    content: "\f005";
                    font-size: 1.8rem;
                    color: $secondary;
                    float: left;
                    margin-right: 15px;
                    height: 45px;
                }

                &:last-of-type {
                    margin-top: 20px;

                    &:before {
                        content: "\f0c7";
                        font-size: 1.6rem;
                    }
                }
            }
        }
    }

    .inversed {
        .slider-content {
            &:first-of-type {
                -webkit-box-ordinal-group: 3;
                -ms-flex-order: 2;
                order: 2;
            }

            &:last-of-type {
                -webkit-box-ordinal-group: 2;
                -ms-flex-order: 1;
                order: 1;
            }

            &.text-content {
                section {
                    padding: 0 20px;
                }
            }
        }
    }

    &.slider-component-init {
        article {
            display: none;

            &:first-of-type {
                display: block;
            }
        }

        &.slick-initialized {
            article {
                display: block;
            }
        }
    }

    &.slick-slider {
        padding: 0 0 85px;
        /*         .inversed {
            
        } */
    }
}

/* MEDIA QUERIES */
@media (max-width: $media_xl) {
    .slider-component {
        .navi {
            &.navi-prev {
                left: -30px;
            }

            &.navi-next {
                right: -30px;
            }
        }
    }
}

@media (max-width: $media_md) {
    .slider-component-container {
        padding: 60px 0;

        .slider-title {
            font-size: 4rem;
        }

        .slider-component {

            /*    padding: 50px 0; */
            header * {
                font-size: 3rem;
            }

            .slider-content,
            .inversed {
                &:first-of-type {
                    -webkit-box-ordinal-group: 2;
                    -ms-flex-order: 1;
                    order: 1;
                }

                &:last-of-type {
                    -webkit-box-ordinal-group: 3;
                    -ms-flex-order: 2;
                    order: 2;
                }
            }

            .inversed {
                .text-content {
                    section {
                        padding: 0;
                    }
                }
            }
        }
    }
}