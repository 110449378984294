.references-group-section {
    color: color(text-darker);
    z-index: 10;
    margin-top: -500px;

    .section-border {
        background-size: cover !important;
        height: 445px;
        z-index: 10;
    }

    .page-content {
        z-index: 10;
        background: color(white-clr);
        // padding-top: 380px;
        padding-bottom: 100px;

        @media (max-width: $media_md) {
            padding-bottom: 80px;
        }

        @media (max-width: $media_sm) {
            padding-bottom: 50px;
        }
    }

    .main-content {
        z-index: 30;
        position: relative;
        top: -100px;

        @media(max-width: $media_sm) {
            top: -180px;
        }

        @media (max-width: $media_xs) {
            top: -220px;
        }
    }

    .references-title {
        font-weight: 200;
        font-family: font(poppins);
        text-align: center;
        font-size: 2.857rem;
    }

    hr.references-separator {
        border-top: 3px solid color(text-darker);
        width: 30px;
    }

    .references-desc {
        font-family: font(roboto) !important;
        font-weight: 300 !important;
        font-size: 1.143rem !important;
        
        p {
            font-weight: 300;
            font-size: 1.143rem !important;
            margin-bottom: 1rem;
        }
        strong{
            font-weight: 700;
        }
        a{
            color: #142833;
            @include transition(all .3s linear);
            img{
                @include transition(all .3s linear);
            }
            &:hover{
                font-weight: 700;
                color: #142833;
                img{
                    opacity: 0.7;
                }
            }
        }
    }

    .ref-btn {
        text-decoration: none;
        text-transform: uppercase;
        font-family: font(roboto);
        font-weight: 700;
        color: color(text-darker);
        background: transparent;
        border: none;
        border-radius: 0;
        font-size: 1rem;
        padding: 0.8rem 2rem;
        @include transition(all .3s linear);

        &.active {
            background: color(primary-clr);
            color: color(white-clr);
        }
    }

    .all-btn {
        @extend .ref-btn;
    }

    .category-tabs {
        margin-bottom: 5rem;
        margin-top: 5rem;

        .category-item {
            padding-bottom: 5px;

            &:not(:last-of-type) {
                border-right: 1px solid #cccccc;
                padding-right: 5px;
                padding-left: 5px;
            }

            &:nth-of-type(1st) {
                border: none !important;
            }
            @media(max-width: $media_sm){
                display: block;
                margin-bottom: 5px;
            }
        }
    }

    .reference-item {
        text-align: center;
        margin-bottom: 3rem;
        .reference-link{
            &:hover{
                text-decoration: none;
            }
        }
        .category-icon-wrapper {
            margin-bottom: 0.5rem;

            img {
                max-width: 52px;
                max-height: 52px;
            }
        }

        .reference-title {
            font-family: font(poppins);
            font-weight: 200;
            font-size: 1.8rem;
        }

        hr.reference-separator {
            border-top: 3px solid color(text-darker);
            width: 30px;
        }

        .reference-desc {
            font-family: font(roboto);
            font-weight: 300;
            font-size: 1rem;
            margin-bottom: 3rem;

            @include page-text();
        }

        .reference-btn {
            font-weight: 500;
            font-family: font(poppins);
            font-size: 1rem;
            text-decoration: none;
            padding: 7px 15px;
            color: color(text-darker);
            border: 1px solid color(primary-clr);
            transition: all .3s linear;

            &:hover {
                color: color(white-clr);
                background: color(primary-clr);
            }
        }


        &:nth-child(even) {
            .order-first {
                -ms-flex-order: 13;
                order: 13;
            }

            .order-last {
                -ms-flex-order: -1;
                order: -1;
            }

            @media (max-width: $media_md) {
                .order-first {
                    -ms-flex-order: -1;
                    order: -1;
                }

                .order-last {
                    -ms-flex-order: 13;
                    order: 13;
                }
            }
        }

    }
    @media(max-width: $media_md){
        .category-icon-wrapper{
            margin-top: 40px;
        }
    }
}



// old
/* REFERENCES COMPONENT STYLES */
// .references-component {
//     padding: 80px 0;
//     text-align: center;
//     color: #ffffff;
//     background: $dark url('../../assets/images/references-bg.jpg') no-repeat;
//     background-size: cover;

//     .references-title {
//         font-size: 4.8rem;
//         max-width: 900px;
//         margin: 0 auto 35px;
//     }

//     p {
//         max-width: 900px;
//         margin: 0 auto;
//         font-size: 2.2rem;

//         strong,
//         b,
//         span {
//             font-weight: 700;
//         }
//     }

//     .clients-slider {
//         padding: 35px 0;

//         img {
//             margin: auto;
//             @include opacity(0.5);
//         }

//         @media (max-width: $media_md) {
//             .navi {
//                 display: inline-block !important;
//             }
//         }
//     }

//     hr {
//         margin: 4.5rem 0;
//         border-color: rgba(255, 255, 255, 0.1);
//     }
// }

// .references-wrapper {
//     .slick-dots {
//         position: relative;
//         bottom: 0;
//         margin-top: 35px;

//         li {
//             button {
//                 border-color: #ffffff;
//             }

//             &.slick-active {
//                 button {
//                     background-color: #ffffff;
//                 }
//             }
//         }
//     }
// }

// .references-slider {
//     img {
//         margin: 0 auto 30px;
//     }

//     .title {
//         font-size: 1.6rem;
//         font-weight: 400;
//         margin-bottom: 30px;
//     }

//     p {
//         font-size: 1.6rem;
//         max-width: 900px;
//         margin: 0 auto;
//     }

//     .navi {
//         top: auto;
//         bottom: -10px;
//         color: #ffffff;

//         &.navi-prev {
//             right: 50%;
//             left: auto;
//             margin-right: 50px;
//         }

//         &.navi-next {
//             right: auto;
//             left: 50%;
//             margin-left: 50px;
//         }

//         &.slick-disabled {
//             opacity: 0.2;
//         }
//     }
// }

// /* LIST TYPE SLICK SLIDER */
// .slider-list-init {
//     .slick-track {
//         @include d-flex;
//         @include align-items-center;
//     }

//     .navi {
//         color: #ffffff;
//         transition: opacity 0.15s;

//         &.navi-prev {
//             left: -75px;
//         }

//         &.navi-next {
//             right: -75px;
//         }

//         &:hover {
//             opacity: 1;
//         }

//         &.slick-disabled {
//             opacity: 0.2;
//         }
//     }
// }

// /* MEDIA QUERIES */
// @media (max-width: $media_xl) {
//     .slider-list-init {
//         .navi {
//             &.navi-prev {
//                 left: -20px;
//             }

//             &.navi-next {
//                 right: -20px;
//             }
//         }
//     }
// }

// @media (max-width: $media_md) {
//     .references-component {
//         padding: 60px 0;

//         .references-title {
//             font-size: 4rem;
//         }

//         p {
//             font-size: 1.9rem;
//         }
//     }

//     .references-slider {
//         p {
//             font-size: 1.5rem;
//         }
//     }
// }