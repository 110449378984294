.section {
    &.about-us-home-section {
        z-index: 10;
        margin-top: -430px; // add after header
        color: color(white-clr);

        .section-border {
            height: 35px;
            z-index: 10;
        }

        .page-content {
            @include gradient-two-clr-linear(#558c30, #c1d22b);
            z-index: 10;
            padding-bottom: 380px;
            padding-top: 380px;
        }

        .main-content {
            z-index: 30 !important;
            position: relative !important;
            padding-top: 20px;

            .about-title {
                font-weight: 200;
                font-family: font(poppins);
                font-size: 2.857rem;
                text-align: left;
            }

            hr.about-separator {
                border-top: 3px solid color(white-clr);
                width: 30px;
                text-align: left;
                margin-left: 0;
                margin-top: 2rem;
                margin-bottom: 2rem;
            }

            .about-text {
                font-family: font(roboto);
                font-weight: 300;
                text-align: left;
                font-size: 1rem;

                p {
                    margin-top: 0;
                    margin-bottom: 1rem;
                    font-size: 1rem;
                    font-family: font(roboto);
                    font-weight: 300;
                    line-height: 1.5;
                }
            }
        }

        @media(max-width: $media_md) {

            .page-content {
                padding-top: 280px;
            }
        }
        @media (max-width: $media_md){
            .main-content{
                .about-title {
                    text-align: center;
                }
        
                hr.about-separator {
                    text-align: center !important;
                    margin-left: auto !important;
                }
        
                .about-text {
                    text-align: center;
                }
            }
            
        }
    }


}