.contact-block-section {
    z-index: 10;
    //margin-top: -431px;
    color: color(text-darker);

    .page-content {
        z-index: 10;
        background: color(white-clr);
        // margin-top: -80px;
        padding-bottom: 280px;

        @media(max-width: $media_sm) {
            padding-bottom: 210px;
        }

        @media (max-width: $media_xs) {
            padding-bottom: 140px;
        }
    }

    .main-content {
        z-index: 30;
        position: relative;
        margin-bottom: 2rem;
        top: -100px;

        @media(max-width: $media_sm) {
            top: -180px;
        }

        @media (max-width: $media_xs) {
            top: -220px;
        }
    }

    .contact-title {
        font-family: font(poppins);
        font-weight: 200;
        font-size: 2.857rem;
    }

    hr.contact-separator {
        border-top: 3px solid color(text-dark);
        width: 30px;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .contact-text {
        font-family: font(roboto);
        font-weight: 300;
        font-size: 1.143rem;
        text-align: center;
        margin-bottom: 4rem;
    }

    .contact-btn {
        background: color(primary-clr);
        text-decoration: none;
        text-transform: uppercase;
        font-family: font(poppins);
        font-size: 1.125rem;
        font-weight: 600;
        color: color(white-clr);
        padding: 10px 20px;
        @include transition(all .3s linear);

        &:hover {
            color: color(text-darker);
            background: transparent;
            border: 2px solid color(primary-clr);
        }
    }

}
