.pdf-container {
    padding-top: 5vh;
    padding-bottom: 5vh;

    .pdf-item {
        padding-top: 5px;

        a {
            text-transform: uppercase;
            font-weight: 700;
            font-family: font(poppins);
        }
    }
}