.about-us-section {
    margin-top: -500px;
    color: color(text-darker);
    z-index: 10;

    .section-border {
        background-size: cover !important;
        height: 445px;
        z-index: 10;

    }

    .page-content {
        z-index: 10;
        background: color(white-clr);
        // padding-top: 380px;
        padding-bottom: 100px;

        @media (max-width: $media_md) {
            padding-bottom: 80px;
        }

        @media (max-width: $media_sm) {
            padding-bottom: 50px;
        }
    }

    .main-content {
        z-index: 30;
        position: relative;
        top: -100px;

        @media(max-width: $media_sm) {
            top: -180px;
        }

        @media (max-width: $media_xs) {
            top: -220px;
        }
    }

    .about-us-title {
        font-weight: 200;
        font-family: font(poppins);
        text-align: center;
        font-size: 2.857rem;
    }

    hr.about-us-separator {
        border-top: 3px solid color(text-dark);
        width: 30px;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .about-us-subtitle {
        text-align: center;
        font-family: font(roboto);
        font-weight: 300;
        font-size: 1.143rem;
        margin-bottom: 4rem;
    }
    .header-slider{
        margin-bottom: 7rem;
    }
    .about-us-text {
        font-family: font(roboto);
        font-weight: 300;
        font-size: 1.143rem;
        
        @include page-text();
    }
}