//@import "~bootstrap/scss/bootstrap";
@import "../../node_modules/aos/dist/aos.css";
@import "elements/mixins";
@import "elements/global";

@import "elements/buttons";
@import "elements/bg_img";

@import "elements/header";
@import "elements/footer";
@import "elements/cookie";

@import "elements/main_navigation";
@import "elements/header_slider";
@import "elements/homepage_components/homepage";
@import "elements/smallheader_component";
@import "elements/references_component";
@import "elements/services_component";
@import "elements/references_detail_component";
@import "elements/solutions_component";
@import "elements/services_detail_component";
@import "elements/solutions_detail_component";
@import "elements/blog_detail_component";
@import "elements/testimonials_slider_component";
@import "elements/blogs_component";
@import "elements/contact_block_component";
@import "elements/about_us_component";
@import "elements/terms_and_conditions_component";
@import "elements/not_found";
@import "elements/featured_block_component";
@import "elements/pdf_download";

@import "elements/text_page_component";
@import "elements/sitemap_component";
// @import "elements/comparison_overview";
@import "elements/gmap_component";
@import "elements/dropdown_form_component";

@import "elements/action_component";


@import "elements/slider_component";

@import "elements/home_video_component";
@import "elements/text_component";
@import "elements/form";
@import "elements/scrollbar";
@import "elements/grid_component";
@import "elements/flash_messages";