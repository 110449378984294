.service-section {
    margin-top: -500px;
    color: color(text-darker);
    z-index: 10;

    .section-border {
        background-size: cover !important;
        height: 445px;
        z-index: 10;

    }

    .page-content {
        z-index: 10;
        background: color(white-clr);
        // padding-top: 380px;
        padding-bottom: 100px;

        @media (max-width: $media_md) {
            padding-bottom: 80px;
        }

        @media (max-width: $media_sm) {
            padding-bottom: 50px;
        }
    }

    .main-content {
        z-index: 30;
        position: relative;
        top: -100px;

        @media(max-width: $media_sm) {
            top: -180px;
        }

        @media (max-width: $media_xs) {
            top: -220px;
        }
    }

    .service-title {
        font-weight: 200;
        font-family: font(poppins);
        text-align: center;
        font-size: 2.857rem;
    }

    hr.service-separator {
        border-top: 3px solid color(text-dark);
        width: 30px;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .service-subtitle {
        text-align: center;
        font-family: font(roboto);
        font-weight: 300;
        font-size: 1.143rem;
    }

    .service-text {
        margin-top: 5rem;
        font-family: font(roboto);
        font-weight: 300;
        font-size: 1.143rem;

        @include page-text();
    }
    .more-btn-wrapper{
        margin-top: 5rem;
    }
    .more-btn {
        font-weight: 500;
        font-family: font(poppins);
        font-size: 1.2855rem;
        text-decoration: none;
        padding: 7px 15px;
        color: color(text-darker);
        border: 1px solid color(primary-clr);
        transition: all .3s linear;

        &:hover {
            color: color(white-clr);
            background: color(primary-clr);
        }
    }
}