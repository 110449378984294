#reserve-btn{
    text-transform: uppercase;
    font-family: font(poppins);
    font-weight: 700;
    font-size: 1rem;
    padding: 10px 15px;
}

.dropdown-form-reserve-wrapper {
    display: none;
    width: 500px;
    height: auto;
    z-index: 3000;
    position: absolute;
    background: color(white-clr);
    right: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 20px;
    padding-left: 20px;
    border-radius: 10px;

    .dropdown-form-close-btn {
        display: block;
        position: absolute;
        font-size: 1.3rem;
        right: 0;
        top: 0;

        i {
            color: color(btn-clr-large);
            font-size: 1.3rem;
        }
    }
}

.mobile-dropdown-form-reserve-wrapper{
    display: none;
    width: 500px;
    height: auto;
    z-index: 3000;
    position: absolute;
    background: color(white-clr);
    right:0;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 20px;
    padding-left: 20px;
    border-radius: 10px;
    
    .dropdown-form-close-btn {
        display: block;
        position: absolute;
        font-size: 1.3rem;
        right: 20px;
        top: 5px;

        i {
            color: color(btn-clr-large);
            font-size: 1.3rem;
        }
    }
}
@media (max-width: 505px){
    .mobile-dropdown-form-reserve-wrapper{
        max-width: 280px;
        width: 280px;
    }
}


#dropdown-reserve-form {
    color: color(text-dark);
    font-family: font(poppins);
    font-size: 1rem;
    z-index: 3001;
    width: 100%;
    label{
        display: inline !important;
    }
    input {
        width: 100%;
    }

    i {
        color: #daaf5c;
        font-size: 1.3rem;
    }
}
#mobile-dropdown-reserve-form {
    color: color(text-dark);
    font-family: font(poppins);
    font-size: 1rem;
    z-index: 3001;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 5px;

    input {
        width: 100%;
    }

    i {
        color: #daaf5c;
        font-size: 1.3rem;
        padding-right: 5px !important;
    }
}

.ui-widget {
    font-family: font(poppins) !important;
}

.ui-widget-header {
    color: color(text-darker);
    background: color(white-clr);

    a {
        color: white !important;

        &:hover {
            background-color: color(white-clr) !important;
        }


    }
}

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
    background-color: color(btn-clr-large);
    color: color(white-clr);
}