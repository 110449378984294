.blogs-section {
    color: color(text-darker);
    z-index: 10;

    // margin-top: -500px;
    .section-border {
        background-size: cover !important;
        height: 445px;
        z-index: 10;
    }

    .page-content {
        z-index: 10;
        background: color(white-clr);
        // padding-top: 380px;
        padding-bottom: 280px;

        @media(max-width: $media_sm) {
            padding-bottom: 210px;
        }

        @media (max-width: $media_xs) {
            padding-bottom: 140px;
        }
    }

    .main-content {
        z-index: 30;
        position: relative;
        top: -100px;

        @media(max-width: $media_sm) {
            top: -180px;
        }

        @media (max-width: $media_xs) {
            top: -220px;
        }
    }

    .blogs-title {
        font-weight: 200;
        font-family: font(poppins);
        text-align: center;
        font-size: 2.857rem;
    }

    hr.blogs-separator {
        border-top: 3px solid color(text-darker);
        width: 30px;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .blogs-subtitle {
        font-weight: 300;
        font-family: font(roboto);
        text-align: center;
        font-size: 1.143rem !important;
    }

    .blogs-text {

        color: color(text-darker);

        @include page-text();
    }

    .blogs-container {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .pagi-container {
        margin-top: 5rem;
        margin-bottom: 1rem;

        .pagination {
            font-size: 1.143rem;
            font-family: font(roboto);
            font-weight: 300;

            .page-item {
                color: color(text-darker);

                &.active {

                    color: color(white-clr) !important;
                    background: color(primary-clr);
                }

                &.prev {
                    &.disabled {
                        .page-link {
                            color: color(text-dark) !important;
                            opacity: 0.7;
                        }

                        &:hover {
                            cursor: not-allowed;
                        }
                    }

                    .page-link {
                        color: color(text-darker) !important;
                    }
                }

                &.next {
                    &.disabled {
                        .page-link {
                            color: color(text-dark) !important;
                            opacity: 0.7;
                        }

                        &:hover {
                            cursor: not-allowed;
                        }
                    }

                    .page-link {
                        color: color(text-darker) !important;

                    }
                }

                .page-link {
                    background: transparent;
                    @include transition(all .3s linear);

                    &:hover {
                        color: color(white-clr);
                        background: color(primary-clr);
                    }
                }
            }
        }
    }

    .blog-item {
        text-align: left;
        padding-bottom: 1.5rem;
        .blog-link{
            &:hover{
                text-decoration: none;
            }
        }


        .blog-img-wrapper {
            img {
                vertical-align: middle;
            }
        }

        .blog-date {
            font-family: font(roboto);
            font-weight: 600;
            font-size: 0.9rem;
            text-align: left;
            display: block;
            margin-top: 1rem;
            margin-bottom: 0.5rem;
        }

        .blog-title {
            font-family: font(poppins);
            font-weight: 200;
            font-size: 1.357rem;

        }

        .blog-desc {
            font-family: font(roboto);
            font-weight: 300;
            font-size: 1.143rem;
            margin-top: 1rem;
            margin-bottom: 1rem;
            max-height: 125px !important;
            overflow: hidden !important;

            p {
                font-weight: 300;
                font-size: 1rem;

            }
        }

        .more {
            font-family: font(poppins);
            font-weight: 500;
            text-decoration: none;
            border: 1px solid #ccc;
            padding: 5px 20px;
            @include transition(all .3s linear);
            margin-bottom: 1rem;

            &:hover {
                color: color(white-clr);
                background-color: color(primary-clr);
            }
        }
    }

}
