/* FONT IMPORTS */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,800');
@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,700,800');
/* THEME COLORS */

$primary: #c90000; // replace with color(primary-clr)
$secondary: #adc400; // replace with color(secondary-clr)
$terniary: #1870b4; // replace with color(terniary-clr);
$dark: #475966; // clear
// $text_dark: $dark;
$border_light: rgba(255, 255, 255, 0.3); // clear
$border_radius: 3px; // clear
$background_main: #ffffff; // replace
$background_alt: #f6f6f6; // replace 

$clr-theme-light: #8fcb86; // delete components
$clr-theme-light-b: #3fb4d7; // delte
$text-dark: #666666; // replace with color(text-dark)


$colors: (primary-clr: #92c01f,
secondary-clr: #fff,
terniary-clr: #fff,
btn-clr-large: #ad824b, // clear
color-them-light: #f9f6f2, // clear
subtitle-color: #daaf5c,
white-clr: #ffffff,
text-darker: #333333,
text-dark: #666666,
text-light: #999999,
black-clr: #000000,
);

@function color($key) {
    @if map-has-key($colors, $key) {
        @return map-get($colors, $key);
    }

    @warn "Unknown `#{$key}` in $colors.";
    @return null;
}

/* DUPLICATED VARIABLES */
$footer_color_accent: #ffffff;

/* FONTS */
$font_main: 'Open Sans',
sans-serif;
//$font_alt: 'Raleway',
//sans-serif;
//$font_Unica_One: 'Unica One',
//cursive;
//$font_Open_Sans: 'Open Sans',
//sans-serif;
//$font_Chivo: 'Chivo',
//sans-serif;

/* CSS VARIABLES (BOOTSTRAP OVERRIDES) */
:root {
    /* BOOTSTRAP ROOT VARS
// Uncomment to change vars
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #ffffff;
    --gray: #868e96;
    --gray-dark: #343a40;
*/
    --primary: #c90000;
    --secondary: #adc400;
    --terniary: #1870b4;
    /*  
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
*/
    --dark: #475966;
    /*  
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
*/
}

/* MEDIA QUERY BREAKPOINTS */
$media_xs: 420px;
$media_sm: 576px;
$media_md: 768px;
$media_lg: 992px;
$media_xl: 1200px;
$media_xxl: 1400px;

$fonts: (poppins: ('Poppins', sans-serif),
roboto: ('Roboto', sans-serif),
);

@function font($key) {
    @if map-has-key($fonts, $key) {
        @return map-get($fonts, $key);
    }

    @warn "Unknown `#{$key}` in $fonts.";
    @return null;
}

/* GLOBAL HTML TAG STYLES AND RESETS */
html {
    font-size: 14px;

    body {
        background-color: color(white-clr);
        color: color(text-darker);


        // font-family: $font_main;
        font-family: font(poppins);
        overflow-x: hidden;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        width: 100%;
       
        &.menu-is-open {
            position: relative;
            /*             overflow: hidden;
            max-height: 100%; */
        }
    }
}

.pattern-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 500px;
    z-index: 20 !important;
}


.main-header {
    color: color(white-clr);
    text-align: center;
    font-family: font(poppins);

    .image-layer {
        background-size: cover !important;
        height: 1060px;
        z-index: 30;
        position: relative;
        max-width: 100vw;
    }

    .header-title {
        font-weight: 200;
        font-size: 5.174rem;
        padding-top: 40vh;
        display: block;
    }

    .header-subtitle {
        font-weight: 200;
        font-size: 4.5rem;
    }

    .header-text {
        font-weight: 200;
        font-size: 1.357rem;

        p {
            font-weight: 200;
            font-size: 1.357rem;
            margin-bottom: 1rem;
        }
    }

    @media (max-width: $media_md) {
        .header-title {
            padding-top: 30vh;
            font-size: 3.174rem;
        }
    }

}

.section {

    // old check if needed

    &.video-show {
        background-color: #f2f2f2;
    }
}

.contact-section {
    margin-top: -500px;
    color: color(text-darker);
    z-index: 10;

    .section-border {
        background-size: cover !important;
        height: 445px;
        z-index: 10;

    }

    .page-content {
        z-index: 10;
        background: color(white-clr);
        // padding-top: 380px;
        padding-bottom: 280px;

        @media(max-width: $media_sm) {
            padding-bottom: 210px;
        }

        @media (max-width: $media_xs) {
            padding-bottom: 140px;
        }
    }

    .main-content {
        z-index: 30;
        position: relative;
        top: -100px;

        @media(max-width: $media_sm) {
            top: -180px;
        }

        @media (max-width: $media_xs) {
            top: -220px;
        }
    }

    .contact-title {
        font-weight: 200;
        font-family: font(poppins);
        text-align: center;
        font-size: 2.857rem;
    }

    hr.contact-separator {
        border-top: 3px solid color(text-darker);
        width: 30px;
    }

    .contact-text {
        font-weight: 300;
        font-family: font(roboto);
        font-size: 1.143rem !important;
        margin-bottom: 5rem;
        margin-top: 5rem;

        @include page-text();
    }

    .contact-btn {
        background: color(primary-clr);
        border: 2px solid color(primary-clr);
        text-decoration: none;
        text-transform: uppercase;
        font-family: font(poppins);
        font-size: 1.125rem;
        font-weight: 600;
        color: color(white-clr);
        padding: 10px 20px;
        @include transition(all .3s linear);

        &:hover {
            color: color(text-darker);
            background: transparent;
            border: 2px solid color(primary-clr);
        }
    }
}

// new end

hr.text-divider {
    border-top: 3px solid color(subtitle-color);
    width: 10%;
}

hr.half {
    border-top: 3px solid color(subtitle-color);
    width: 50%;
}

// old

a {
    @include transition(all 0.1s ease-in);

    &:link,
    &:visited,
    &:hover,
    &:active {
        color: inherit;
        outline: none;
    }
}

ul {
    list-style: none;
    padding: 0;
    font-size: 1.3rem;
}

button {
    border: 0;
    background-color: transparent;
    color: $text_dark;
    padding: 0;
    cursor: pointer;

    &:focus {
        outline: 0;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
    margin: 0;
}

p {
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25;
}

.red {
    color: #c90000 !important;
}

.error {
    border-color: #c90000 !important;
}

/* BOOTSTRAP OVERRIDES */
.card {
    border: 0;
    background-color: transparent;

    .card-header {
        border: 0;
        background-color: transparent;
    }

    .card-footer {
        border: 0;
        background-color: transparent;
    }
}


.text-muted {
    font-size: 1.2rem !important;
    color: $footer_color_accent !important;
    @include opacity(0.35);
}

/* Global TEXT styling */
.page-subtitle {
    padding-top: 7vh;
    padding-bottom: 7vh;
    color: $clr-theme-light-b;
}


.page-text {
    font-size: 1rem;

    p {
        font-size: 1rem;
        line-height: 1.5;
        font-family: font(roboto);
    }
}


/* BACKGROUND ALTERNATE CLASS */
.bg-alt {
    background-color: $background_alt !important;
    color: $dark !important;

    .dmn-button {
        &.alternate {
            color: #FFF;
        }
    }
}

/* GLOBAL LINK BUTTONS */
/* Button with primary background color */
.dmn-button {
    font-size: 1.4rem;
    font-weight: 600;
    padding: 0 12px;
    line-height: 34px;
    display: inline-block;
    background-color: $primary;
    border: 1px solid $primary;
    color: #ffffff;
    border-radius: $border_radius;
    @include transition(all 0.15s ease-in-out);

    &:link {
        color: #ffffff;
    }

    &:hover {
        text-decoration: none;
        background-color: darken($primary, 5%);
        border-color: darken($primary, 5%);
    }

    /* BACKGROUND FILL ANIMATION --> remove if not using */
    &.fill {
        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: $primary;
            z-index: -1;
            @include transition(top 0.1s ease-in);
        }

        &:hover {
            &:before {
                top: 100%;
            }
        }
    }

    /* ARROW ANIMATION */
    &.arrow {
        overflow: hidden;
        position: relative;

        &:after {
            content: "\f178";
            font-family: 'FontAwesome';
            opacity: 0;
            font-weight: 100;
            width: 0;
            display: inline-block;
            text-indent: 0;
            @include transition(all 250ms cubic-bezier(0.680, -0.550, 0.265, 1));
        }

        &:hover {
            &:after {
                width: 20px;
                text-indent: 8px;
                opacity: 1;
            }
        }
    }

    /* Button link */
    &.link {
        color: $primary;
        font-weight: 400;
        padding: 0;
        border: 0;
        background-color: transparent !important;

        i {
            margin-left: 10px;
        }

        &:hover {
            i {
                -webkit-animation: leftToRight 1s ease-out infinite;
                animation: leftToRight 1s ease-out infinite;
            }
        }
    }

    /* Button with light border, text color and transparent background */
    &.outline {
        background-color: transparent !important;
        border-color: $border_light;
        color: #ffffff;
        font-weight: 400;
    }

    /* Button with secondary background color */
    &.secondary {
        background-color: $secondary;
        border-color: $secondary;

        &:hover {
            background-color: darken($secondary, 5%);
            border-color: darken($secondary, 5%);
        }
    }

    /* Button with dark background color */
    &.dark {
        background-color: $dark;
        border-color: $dark;
        color: #ffffff;

        &:hover {
            background-color: darken($dark, 5%);
            border-color: darken($dark, 5%);
        }
    }

    &[type="submit"] {
        cursor: pointer;
    }
}

/* BACK TO TOP */
.back-to-top {
    width: 40px;
    height: 40px;
    border-radius: $border_radius;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
    line-height: 40px;
    text-align: center;
    display: inline-block;
    // background-color: $secondary;
    background: color(text-dark);
    position: fixed;
    right: 5px;
    bottom: 10px; // 20px
    transform: translate(200px, 0);
    transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    z-index: 30 !important;

    i {
        font-size: 18px;
        color: #ffffff;
    }

    &.animate-in {
        transform: translate(0, 0);
    }

    &.animate-out {
        transform: transalte(200px, 0);
    }
}

/* BUTTON CLOSE */
.btn-close {
    // padding: 17px 53px;
    padding-left: 10px;
    color: #ffffff;
    font-size: 2rem;
    @include opacity(0.7);
    text-transform: uppercase;
    
    .btn-close-icon {
        font-size: 2rem;
        border-radius: 50%;
        display: inline-block;
        // padding-right: 16px;
        // padding-left: 16px;
    }

    .btn-close-text {
        position: relative;
        top: -25%;
        left: 5%;
        line-height: 38px;
        display: inline-block;

    }

    &:hover {
        @include opacity(1);
    }

}

/* GLOBAL CONTACT CARD STYLES */
.contact-card {
    font-size: 1rem;
    font-family: font(poppins);
    color: color(text-dark);

    .contact-title {
        font-family: font(poppins);
        font-size: 1.4rem;
        text-transform: uppercase;
        margin-bottom: 15px;
    }

    i {
        margin-right: 10px;
    }

    .accent {
        font-weight: 700;
        color: color(text-dark) !important;
    }
}

/* GLOBAL IMAGE RADIO INPUT TYPE STYLES */
.dm-img-rd-wrapper {
    @include d-flex;
    flex-wrap: nowrap !important;
    justify-content: space-between;
    text-align: center;
}

.dm-img-rd {
    @include d-flex;
    @include align-items-center;
    flex-direction: column;
    margin-bottom: 3em;
    position: relative;

    [type="radio"] {
        width: 25px;
        height: 25px;
        outline: none;
        visibility: hidden;
        position: absolute;

        &:checked+label {
            &:before {
                background: $secondary;
                border-color: $secondary;
                box-shadow: inset 0px 0px 0px 4px #ffffff;
            }

            img {
                opacity: 1;
            }
        }
    }

    label {
        cursor: pointer;

        .label-title {
            font-size: 3.2rem;
            font-weight: 300;
        }

        &:before {
            content: "";
            display: inline-block;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            border: 1px solid #d9d9d9;
        }
    }

    img {
        width: 100%;
        max-width: 242px;
        padding: 20px 0;
        opacity: 0.8;
        transform: opacity 0.2s;
    }
}

/* GLOBAL KEYFRAMES */
@-webkit-keyframes leftToRight {
    0% {
        transform: translateX(0);
    }

    60% {
        transform: translateX(10px);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes leftToRight {
    0% {
        transform: translateX(0);
    }

    60% {
        transform: translateX(10px);
    }

    100% {
        transform: translateX(0);
    }
}

/* MEDIA QUERIES */
@media (max-width: $media_lg) {
    .card {
        .card-header {
            padding-right: 0;
            padding-left: 0;
        }

        .card-body {
            padding-right: 0;
            padding-left: 0;
        }
    }

    body {
        &.menu-is-open {
            overflow: hidden;
            max-height: 100%;
        }
    }
}

@media (max-width: $media_sm) {
    .dmn-button {
        font-size: 1.3rem;
        line-height: 30px;
    }

    p {
        font-size: 1.7rem;
    }
}

@media(max-width: $media_xs) {
    .dm-img-rd-wrapper {
        flex-direction: column;
    }

    .dm-img-rd {
        img {
            padding: 20px 10px;
        }
    }
}

.alert {
    position: absolute;
    width: 310px;
    top: 30%;
    left: 50%;
    margin-left: -130px;
    z-index: 9999;
    font-size: 16px;
    padding: 40px;
}

.table-legend {
    position: relative;
    width: 100%;
    text-align: right;

    @media (max-width: $media_md) {
        text-align: center;
    }

    ul {
        position: relative;
        top: 100px;
        right: 20px;

        @media (max-width: $media_md) {
            right: auto;
        }

        li {
            display: inline-block;

            &:first-child {
                border-right: 1px solid #CCC;
                margin-right: 15px;
                padding-right: 15px;
            }

            >i {
                margin-right: 5px;
            }
        }
    }
}

.references-component {
    .clients-slider {
        .slick-slide {
            height: 35px;
            background-position: center center;
            background-repeat: no-repeat;
            opacity: .5;
        }
    }
}

[data-aos="border-fade-up"] {
    opacity: 0.5;
    margin-top: -356px !important;
    transition-property: margin-top, opacity;

    &.aos-animate {
        opacity: 1;
        margin-top: -456px !important;
    }
}

[data-aos="border-fade-up-inner"] {
    opacity: 0.5;
    margin-top: -400px !important;
    transition-property: margin-top, opacity;

    &.aos-animate {
        opacity: 1;
        margin-top: -500px !important;
    }
}
