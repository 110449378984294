.banners-wrapper {
    margin-top: 10vh;
    margin-bottom: 10vh;
    color: $clr-theme-light;

    .banner-item {
        a:hover {
            text-decoration: none;
        }

        .banner-image {
            height: 300px;
            width: 300px;
        }

        .banner-title {
            font-size: 2.5rem;
            padding: 1.5rem 5rem;


        }

        .banner-subtitle {
            padding-right: 3rem;
            padding-left: 3rem;
            line-height: 1.5;
            font-size: 2rem;
            color: $clr-theme-light-b;
        }
    }

}

@media (max-width: $media_lg) {
    .banners-wrapper {
        .banner-item {
            padding: 3vh 0;
        }
    }
}

@media (max-width: $media_xs) {
    .banners-wrapper {
        .banner-item {
            .banner-image {
                height: 250px;
                width: 250px;
            }
        }
    }

}