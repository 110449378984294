.services-section {
    margin-top: -500px;
    color: color(text-dark);
    z-index: 10;

    .section-border {
        background-size: cover !important;
        height: 445px;
        z-index: 10;

    }

    .page-content {
        z-index: 10;
        background: color(white-clr);
        // padding-top: 380px;
        padding-bottom: 100px;

        @media (max-width: $media_md) {
            padding-bottom: 80px;
        }

        @media (max-width: $media_sm) {
            padding-bottom: 50px;
        }
    }

    .main-content {
        z-index: 30;
        position: relative;
        top: -100px;

        @media(max-width: $media_sm) {
            top: -180px;
        }

        @media (max-width: $media_xs) {
            top: -220px;
        }
    }

    .services-title {
        font-weight: 200;
        font-family: font(poppins);
        text-align: center;
        font-size: 2.857rem;
    }

    hr.services-separator {
        border-top: 3px solid color(text-dark);
        width: 30px;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .services-subtitle {
        text-align: center;
        font-family: font(roboto);
        font-weight: 300;
        font-size: 1.143rem;
    }

    .services-desc {
        margin-top: 5rem;
        margin-bottom: 5rem;
        font-family: font(roboto);
        font-weight: 300;
        font-size: 1.143rem;
        

        p {
            font-family: font(roboto);
            font-weight: 300;
            font-size: 1.143rem;
            line-height: 1.5;
            padding-bottom: 1rem;
        }

        ul {
            li {
                font-family: font(roboto);
                font-weight: 300;
                font-size: 1.143rem;
            }
        }
        strong{
            font-weight: 700;
        }
        a{
            color: #142833;
            @include transition(all .3s linear);
            img{
                @include transition(all .3s linear);
            }
            &:hover{
                font-weight: 700;
                color: #142833;
                img{
                    opacity: 0.7;
                }
            }
        }
    }

    .service-info {
        font-family: font(poppins);
        font-weight: 500;
        color: color(text-darker);
        margin-bottom: 1rem;
        .service-link{
            &:hover{
                text-decoration: none;
            }
        }
        .service-icon {
            padding-bottom: 1rem;
        }

        .service-title {
            font-size: 1.357rem !important;
            font-weight: 500 !important;
            padding-left: 25px;
            padding-right: 25px;
            margin-bottom: 1rem;
        }

        .service-desc {
            font-weight: 200;

            margin-bottom: 1rem;
            font-family: font(roboto);

            @include page-text();
        }

        .service-more {
            font-family: font(poppins);
            font-weight: 500;
            text-decoration: none;
            border: 1px solid #ccc;
            padding: 5px 20px;
            @include transition(all .3s linear);

            &:hover {
                color: color(white-clr);
                background-color: color(primary-clr);
            }
        }

    }

    @media (max-width: $media_lg){
        .service-info{
            margin-bottom: 40px;
        }
    }
}


// /* SERVICES COMPONENT STYLES */
// .services-component {
//     text-align: center;
//     background-color: $background_main; // $background_alt
//     padding: 120px 0;

//     .services-title {
//         font-family: font(roboto);
//         font-size: 4.8rem;
//         font-weight: 500;
//         margin-bottom: 20px;
//     }

//     p {
//         max-width: 900px;
//         margin: auto;
//     }
// }

// .card-services-container {
//     margin-top: 60px;
// }

// .card-service-wrapper {
//     text-align: center;
//     justify-content: space-between;

//     .card-header,
//     .card-body {
//         padding-right: 0;
//         padding-left: 0;
//     }

//     img {
//         max-width: 350px;
//     }

//     .card-header-title {
//         font-size: 2.8rem;
//         margin: 30px 0 10px;
//     }

//     ul {
//         &:first-of-type {
//             margin-bottom: 40px;

//             li {
//                 font-size: 1.7rem;
//                 line-height: 1.3;
//                 margin-bottom: 10px;

//                 &:before {
//                     font-family: 'FontAwesome';
//                     content: "\f00c";
//                     margin-right: 10px;
//                     font-size: 1.8rem;
//                 }
//             }
//         }

//         &:last-of-type {
//             margin-bottom: 20px;
//             text-align: left;

//             li {
//                 font-size: 1.4rem;
//                 width: 140px;
//                 white-space: nowrap;
//                 margin: auto;

//                 &:before {
//                     font-family: 'FontAwesome';
//                     content: "\f061";
//                     margin-right: 10px;
//                     font-size: 1rem;
//                     position: relative;
//                     bottom: 1px;
//                 }
//             }
//         }
//     }

//     &.secondary {
//         ul {
//             li {
//                 &:before {
//                     color: $secondary;
//                 }
//             }
//         }
//     }

//     &.primary {
//         ul {
//             li {
//                 &:before {
//                     color: $primary;
//                 }
//             }
//         }
//     }

//     &.alternate {
//         ul {
//             li {
//                 &:before {
//                     color: #1870b4;
//                 }
//             }
//         }
//     }
// }

// /* MEDIA QUERIES */
// @media (max-width: $media_md) {
//     .services-component {
//         padding: 60px 0;

//         .services-title {
//             font-size: 4rem;
//         }
//     }

//     .card-services-container {
//         display: -webkit-box;
//         display: -ms-flexbox;
//         display: flex;
//         -webkit-box-orient: vertical;
//         -webkit-box-direction: normal;
//         -ms-flex-direction: column;
//         flex-direction: column;

//         .card-service-wrapper {
//             margin-bottom: 25px;

//             &:last-of-type {
//                 margin-bottom: 0;
//             }
//         }
//     }
// }