/* TEXT COMPONENT STYLES */
.text-component {
    padding: 95px 0;

    p {
        font-size: 1.5rem;
        line-height: 1.4;
        margin: 0 0 30px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    strong,
    span {
        font-weight: 700;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: 2.1rem;
        text-transform: uppercase;
        margin: 0 0 30px;
    }

    ul {
        list-style-type: disc;
        padding-left: 3rem;

        li {
            font-size: 1.5rem;
            line-height: 1.4;
            font-weight: 300;
            margin-bottom: 5px;
        }
    }

    a {
        border-bottom: 1px dotted #475966;

        &:hover {
            text-decoration: none;
            border-bottom: 1px solid #475966;
        }
    }
}

#mobileapp-page {
    .text-component {
        padding-bottom: 0;
        ul {
            font-size: 1.5rem;
            font-weight: 300;
        }
    }
}

.mobile-store-wrapper {
    padding: 30px 0 90px;
    a {
        text-decoration: none !important;
    }

    img {
        margin: 5px; 
        margin-left: 0;
    }
}

/* MEDIA QUERIES */
@media(max-width: $media_md) {
    .text-component {
        padding: 60px 0;
    }
    .mobile-store-wrapper {
        padding-bottom: 60px;
    }
}

@media (max-width: $media_xs) {
    .text-component {
        p {
            margin-bottom: 20px;
        }
        h1,h2,h3,h4,h5,h6 {
            margin-bottom: 20px;
        }
    }
}