.pattern-overlay {
    background-image: url('../../images/background/pattern.png');
}

// .main-header {
//     .image-layer {
//         background: url('../../images/background/header-img-compressed.png') no-repeat center center;
//     }
// }

.section {
    &.references-section {
        .section-border {
            background: url('../../images/background/footer-border.png') no-repeat center center;
        }
    }

    &.contact-home-section {
        .section-border {
            background: url('../../images/background/white-border.png') no-repeat center center;
        }
    }

    &.services-home-section {
        .section-border {
            background: url('../../images/background/white-border.png') no-repeat center center;
        }
    }
}

.references-group-section {
    .section-border {
        background: url('../../images/background/white-border.png') no-repeat center center;
    }
}

.testimonials-slider {
    .slide {
        .testimonial-quote-start {
            background: url('../../images/icons/quote-start.png')
        }

        .testimonial-quote-end {
            background: url('../../images/icons/quote-end.png')
        }
    }

}

.reference-detail-section {
    .section-border {
        background: url('../../images/background/white-border.png') no-repeat center center;
    }
}

.main-footer {
    .footer-border {
        background: url('../../images/background/footer-border.png') no-repeat center center;
    }
}

.header-slider-wrapper {

    .navi {
        &.navi-prev {
            background: url('../../images/icons/slider-prev.png') no-repeat;
        }

        &.navi-next {
            background: url('../../images/icons/slider-next.png') no-repeat;
        }
    }
}

.services-section {
    .section-border {
        background: url('../../images/background/white-border.png') no-repeat center center;
    }
}

.service-section {
    .section-border {
        background: url('../../images/background/white-border.png') no-repeat center center;
    }
}

.solution-section {
    .section-border {
        background: url('../../images/background/white-border.png') no-repeat center center;
    }
}

.blog-section {
    .section-border {
        background: url('../../images/background/white-border.png') no-repeat center center;
    }
}

.contact-section {
    .section-border {
        background: url('../../images/background/white-border.png') no-repeat center center;
    }
}

.sitemap-section {
    .section-border {
        background: url('../../images/background/white-border.png') no-repeat center center;
    }
}

.blogs-section {
    .section-border {
        background: url('../../images/background/white-border.png') no-repeat center center;
    }
}

.solutions-section {
    .section-border {
        background: url('../../images/background/white-border.png') no-repeat center center;
    }
}

.terms-and-conditions-section {
    .section-border {
        background: url('../../images/background/white-border.png') no-repeat center center;
    }
}

.not-found-section {
    .section-border {
        background: url('../../images/background/white-border.png') no-repeat center center;
    }
}

.text-page-section {
    .section-border {
        background: url('../../images/background/white-border.png') no-repeat center center;
    }
}
.about-us-section {
    .section-border {
        background: url('../../images/background/white-border.png') no-repeat center center;
    }
}