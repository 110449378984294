.home-video-holder {
    position: relative;
    max-width: 1100px;
    height: 725px !important;

    .video-img-overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
    }
}
@media (max-width: $media_lg){
    .home-video-holder{
        height: 500px !important;

    }
}
@media(max-width: $media_md){
    .home-video-holder{
        height: 360px !important;
        .play-btn-bg{
            top: 60% !important;
        }
    }
}
@media (max-width: $media_sm){
    .home-video-holder{
        height: 310px !important;
    }
}
@media (max-width: $media_xs){
    .home-video-holder{
        height:250px !important;
    }
}