.solutions-section {
    margin-top: -500px;
    color: color(text-dark);
    z-index: 10;

    .section-border {
        background-size: cover !important;
        height: 445px;
        z-index: 10;

    }

    .page-content {
        z-index: 10;
        background: color(white-clr);
        // padding-top: 380px;
        padding-bottom: 100px;

        @media (max-width: $media_md) {
            padding-bottom: 80px;
        }

        @media (max-width: $media_sm) {
            padding-bottom: 50px;
        }
    }

    .main-content {
        z-index: 30;
        position: relative;
        top: -100px;

        @media(max-width: $media_sm) {
            top: -180px;
        }

        @media (max-width: $media_xs) {
            top: -220px;
        }
    }

    .solutions-title {
        font-weight: 200;
        font-family: font(poppins);
        text-align: center;
        font-size: 2.857rem;
    }

    hr.solutions-separator {
        border-top: 3px solid color(text-dark);
        width: 30px;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .solutions-subtitle {
        text-align: center;
        font-family: font(roboto);
        font-weight: 300;
        font-size: 1.143rem;
    }

    .solutions-desc {
        margin-top: 5rem;
        margin-bottom: 5rem;
        font-family: font(roboto);
        font-weight: 300;
        font-size: 1.143rem;
        

        p {
            font-family: font(roboto);
            font-weight: 300;
            font-size: 1.143rem;
            line-height: 1.5;
            padding-bottom: 1rem;
        }

        ul {
            li {
                font-family: font(roboto);
                font-weight: 300;
                font-size: 1.143rem;
            }
        }
        strong{
            font-weight: 700;
        }
        a{
            color: #142833;
            @include transition(all .3s linear);
            img{
                @include transition(all .3s linear);
            }
            &:hover{
                font-weight: 700;
                color: #142833;
                img{
                    opacity: 0.7;
                }
            }
        }
    }

    .solution-info {
        font-family: font(poppins);
        font-weight: 500;
        color: color(text-darker);
        margin-bottom: 1rem;
        .solution-link{
            &:hover{
                text-decoration: none;
            }
        }
        .solution-icon {
            padding-bottom: 1rem;
        }

        .solution-title {
            font-size: 1.357rem !important;
            font-weight: 500 !important;
            padding-left: 25px;
            padding-right: 25px;
            margin-bottom: 1rem;
        }

        .solution-desc {
            font-weight: 200;
            margin-bottom: 1rem;
            font-family: font(roboto);

            p {
                font-weight: 200;
                margin-bottom: 1rem;
                font-family: font(roboto);
                font-size: 1rem;
            }
        }

        .solution-more {
            font-family: font(poppins);
            font-weight: 500;
            text-decoration: none;
            border: 1px solid #ccc;
            padding: 5px 20px;
            @include transition(all .3s linear);

            &:hover {
                color: color(white-clr);
                background-color: color(primary-clr);
            }
        }

    }
    @media (max-width: $media_lg){
        .solution-info{margin-bottom: 40px;}
        
    }
}