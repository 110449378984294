.section {
    &.references-section {

        margin-top: -436px;
        z-index: 10;
        color: color(white-clr);

        .section-border {
            background-size: cover !important;
            height: 451px;
            z-index: 10;
        }

        .page-content {
            background: #142833;
            z-index: 10;
            // padding-top: 380px;
            padding-bottom: 280px;

            @media(max-width: $media_sm) {
                padding-bottom: 210px;
            }

            @media (max-width: $media_xs) {
                padding-bottom: 140px;
            }
        }

        .main-content {
            z-index: 30;
            position: relative;
            top: -100px;

            @media(max-width: $media_sm) {
                top: -180px;
            }

            @media (max-width: $media_xs) {
                top: -220px;
            }

            .references-title {
                font-family: font(poppins);
                font-weight: 200;
                font-size: 2.857rem;
            }

            hr.references-separator {
                border-top: 3px solid color(white-clr);
                width: 30px;
                margin-top: 2.5rem;
                margin-bottom: 7rem;
            }

            .references-slider {
                margin-bottom: 7rem;

                .slide {
                    .slider-link {
                        text-decoration: none;
                        @include transition(opacity .3s linear);

                        .slider-logo {
                            width: 153px;
                            margin-left: auto;
                            margin-right: auto;
                        }

                        &:hover {
                            opacity: 0.6;
                        }
                    }

                    .logo-spacer {
                        margin-top: 90px;
                        margin-bottom: 90px;
                        @media(max-width: $media_sm){
                            margin-top: 30px;
                            margin-bottom: 30px;
                        }
                    }
                }

                .navi {
                    position: absolute;
                    top: 50%;
                    margin-top: -25px;
                    z-index: 1;
                    cursor: pointer;
                    font-size: 2.5rem;
                    background: transparent;
                    border: none;
                    color: color(white-clr);

                    &.navi-prev {
                        left: -60px;
                    }

                    &.navi-next {
                        right: -60px;
                    }

                    @media (max-width: 1250px) {
                        &.navi-prev {
                            left: -20px;
                        }

                        &.navi-next {
                            right: -20px;
                        }
                    }

                    @media (max-width: $media_md) {
                        display: inline-block !important;
                    }

                    @media (max-width: $media_sm) {
                        &.navi-prev {
                            left: 0;
                        }

                        &.navi-next {
                            right: 0;
                        }
                    }
                }
            }
        }
    }
}