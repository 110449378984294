.section {
    &.services-home-section {
        margin-top: -456px;
        color: color(text-darker);
        z-index: 10;

        .section-border {
            background-size: cover !important;
            height: 445px;
            z-index: 10;
        }

        .page-content {
            z-index: 10;
            background: color(white-clr);
            // padding-top: 380px;
            padding-bottom: 280px;

            @media(max-width: $media_sm) {
                padding-bottom: 210px;
            }

            @media (max-width: $media_xs) {
                padding-bottom: 140px;
            }
        }

        .main-content {
            z-index: 30;
            position: relative;
            top: -100px;

            @media(max-width: $media_sm) {
                top: -180px;
            }

            @media (max-width: $media_xs) {
                top: -220px;
            }
        }

        .services-title {
            font-weight: 200;
            font-family: font(poppins);
            text-align: center;
            font-size: 2.857rem;
        }

        hr.services-separator {
            border-top: 3px solid color(text-darker);
            width: 30px;
            margin-top: 2rem;
            margin-bottom: 2rem;
        }

        .services-subtitle {
            text-align: center;
            font-family: font(roboto);
            font-weight: 300;
            font-size: 1.143rem;
        }

        .service-wrapper {
            margin-top: 4rem;
        }

        .service-info {
            font-family: font(poppins);
            font-weight: 500;

            .service-link{
                &:hover{
                    text-decoration: none;
                }
            }

            .service-icon {
                padding-bottom: 1rem;
            }

            .service-title {
                font-size: 1.072rem !important;
                font-weight: 500 !important;
                padding-left: 65px;
                padding-right: 65px;
            }

            .service-desc {
                font-weight: 200;
                padding-left: 20px;
                padding-right: 20px;
                font-family: font(roboto);
                margin-top: 1rem;
                margin-bottom: 1rem;

                p {
                    font-weight: 200;
                    font-size: 1rem;
                    line-height: 1.5;
                    margin-bottom: 1rem;
                }
            }

            .service-more {
                font-family: font(poppins);
                font-weight: 500;
                font-size: 1rem;
                text-decoration: none;
                border: 1px solid #ccc;
                padding: 5px 20px;
                @include transition(all .3s linear);

                &:hover {
                    color: color(white-clr);
                    background-color: color(primary-clr);
                }
            }

        }

        @media (max-width: $media_lg) {

            margin-top: -500px;


            .service-info {
                margin-bottom: 40px;
            }
        }

        @media (max-width: $media_md) {
            margin-top: -610px;
        }
    }
}